import color from 'color';
import rangeslider from 'rangeslider-pure';
import 'rangeslider-pure/src/range-slider.css';

import './slider-widget.scss';

const createRangeInput = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'range');

  return input;
};

const createSaturationSlider = (initFunction) => {
  const rangeInput = createRangeInput();
  return rangeInput;
};

const createLightnessSlider = (initFunction) => {
  const rangeInput = createRangeInput();
  return rangeInput;
};

const createSliders = () => {
  const saturationSlider = createSaturationSlider();
  const lightnessSlider = createLightnessSlider();

  return { saturationSlider, lightnessSlider };
};

const setSaturationSliderBackground = (slider, { h: hue, l: lightness }) => {
  slider.range.style.background = `linear-gradient(
    to right,
    hsl(${hue}, 0%, ${lightness}%),
    hsl(${hue}, 20%, ${lightness}%),
    hsl(${hue}, 40%, ${lightness}%),
    hsl(${hue}, 60%, ${lightness}%),
    hsl(${hue}, 80%, ${lightness}%),
    hsl(${hue}, 100%, ${lightness}%)
  )`;
};

const setLightnessSliderBackground = (slider, { h: hue, s: saturation }) => {
  slider.range.style.background = `linear-gradient(
    to right,
    hsl(${hue}, ${saturation}%, 0%),
    hsl(${hue}, ${saturation}%, 20%),
    hsl(${hue}, ${saturation}%, 40%),
    hsl(${hue}, ${saturation}%, 60%),
    hsl(${hue}, ${saturation}%, 80%),
    hsl(${hue}, ${saturation}%, 100%)
  )`;
};

const adjustSliderHandle = (slider, value, { h, s, l}) => {
  slider.handle.style.background = `hsl(${h}, ${s}%, ${l}%)`;

  slider.update({ value });
};

const setSliderHandleColor = (slider, color) => {
  slider.handle.style.background = color;
};

const initializeSliders = (container, sliders, hslColorObject) => {
  const {
    saturationSlider: saturationSliderElement,
    lightnessSlider: lightnessSliderElement
  } = sliders;

  container.appendChild(saturationSliderElement);
  container.appendChild(lightnessSliderElement);

  const rangesliderConfiguration = {
    rangeClass: 'orly-slider-widget',
    fillClass: 'orly-slider-widget__fill',

    onSlide: (position, value) => {
      console.log({ position, value });
    }
  };

  rangeslider.create(saturationSliderElement, rangesliderConfiguration);
  rangeslider.create(lightnessSliderElement, rangesliderConfiguration);

  const saturationSlider = saturationSliderElement.rangeSlider;
  const lightnessSlider = lightnessSliderElement.rangeSlider;

  setSaturationSliderBackground(saturationSlider, hslColorObject);
  setLightnessSliderBackground(lightnessSlider, hslColorObject);

  adjustSliderHandle(saturationSlider, hslColorObject.s, hslColorObject);
  adjustSliderHandle(lightnessSlider, hslColorObject.l, hslColorObject);

  return { saturationSlider, lightnessSlider };
};

const setTargetContainerColor = (container, color) => {
  container.style.backgroundColor = color;
};

const convertHslColorObjectToHexString = (hslColorObject) => {
  return color.hsl(hslColorObject).hex().toString().toLowerCase();
};

export const createSliderWidget = (
  container,
  initialColorString,
  targetContainer,
  { onSlide }
) => {
  const initialColor = color(initialColorString);
  const initialColorHslObject = initialColor.hsl().object();

  const sliderElements = createSliders();
  const { saturationSlider, lightnessSlider } = initializeSliders(container, sliderElements, initialColorHslObject);

  saturationSlider.onSlide = (position) => {
    const handleColor = color.hsl({ ...initialColorHslObject, s: position });
    setSliderHandleColor(saturationSlider, handleColor.hex().toString());

    const targetColorObject = {
      ...initialColorHslObject,
      s: position,
      l: lightnessSlider.value,
    };

    const targetColorHexString = convertHslColorObjectToHexString(targetColorObject);

    setTargetContainerColor(targetContainer, targetColorHexString);

    onSlide && onSlide(targetColorHexString);
  };

  lightnessSlider.onSlide = (position) => {
    const handleColor = color.hsl({ ...initialColorHslObject, l: position });
    setSliderHandleColor(lightnessSlider, handleColor.hex().toString());

    const targetColorObject = {
      ...initialColorHslObject,
      l: position,
      s: saturationSlider.value,
    };

    const targetColorHexString = convertHslColorObjectToHexString(targetColorObject);

    setTargetContainerColor(targetContainer, targetColorHexString);

    onSlide && onSlide(targetColorHexString);
  };

  setTargetContainerColor(targetContainer, initialColorString);
};
